@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo Black&display=swap');

:root {
    color-scheme: light;
    --ancillary-font-size: 0.9em;
    --background-color: #fff;
    --border-color: rgba(0, 0, 0, 0.1);
    --row-accent: #fafafa;
    --primary-color: #46759c;
    --navigation-color: linear-gradient(45deg, #346690 0%, #346690 20%, #46759c 100%);
    --secondary-menu-color: #f5f5f5;
    --admin-font-size: 12px;
    --mobile-font-size: 16px;
    --border-radius: 4px;
    --font-family: 'Quicksand', sans-serif;


    /*--border-radius: 2px;*/
    /*--font-family: 'Questrial', sans-serif;*/
    /*--navigation-color: #303a43;*/
}

#root {
    background: var(--background-color);
}

.chat__textarea textarea{
    resize: vertical;
    max-height: 250px;
    height: 60px;
}

body, #root, .app {
    min-height: 100vh;
}

.app {
    display: flex;
    flex-direction: column;
}

.unstyled{
    color: inherit;
}

.rate-table__container--editable td{
    padding: 0.9em;
}

.disclaimer__alert{
    padding: 1em;
    background: var(--row-accent);
    border-left: 3px solid #eeeeee;
    font-weight: lighter;
    pointer-events: none;
    color: #444;
    border-radius: var(--border-radius);
}

.image__container {
    position: relative;
    overflow: hidden;
    text-align: center;
    border-radius: var(--border-radius);
    background: #bdbdbd;
    color: #fff;
}

.image__container svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.image__container img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);
}

.recruiting-logo__image{
    text-align: center;
    padding-top: 1.5em;
}

.recruiting-logo__image img{
    max-width: 500px;
}

.image__container img.horizontal{
    height: 100%;
    width: auto;
}

div, input {
    box-sizing: border-box;
}

.disabled .signature__pad {
    pointer-events: none;
    opacity: 0.5;
}

.disabled{
    pointer-events: none;
    opacity: 0.5;
}

.signature__wrapper{
    /*max-width: 768px;*/
}

.client-schedule__table {
    max-height: 500px;
    overflow-y: scroll;
}

body,
.MuiInputLabel-root,
.MuiChip-root {
    font-size: var(--admin-font-size);
}

body .scope--Employee,
.scope--Employee .MuiAlert-root,
.scope--Employee .MuiButton-root,
.scope--Employee .MuiInputLabel-root {
    font-size: var(--mobile-font-size);
    line-height: 1.5em;
}

.scope--Employee button.text__small{
    line-height: 1.75;
}

body .form__heading {
    border-radius: var(--border-radius);
    background: #f8f8f8;
    padding: 0.5em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
}

.form__heading--standalone {
    margin-top: 1em;
}

.no__bullet{
    list-style: none;
    padding-left: 1em;
}

.employee__badge {
    color: #000;
    position: relative;
    background: #fff;
    margin-left: 0.75em;
    margin-right: 0.75em;
    overflow: hidden;
}

.badge__wrapper {
    display: flex;
    /*font-family: Verdana, sans-serif;*/
    justify-content: center;
}

.badge__wrapper--DNA{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    align-items: center;
    flex-direction: column;
}

.badge__wrapper--DNA .employee__badge{
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    background: #12191d;
}

.badge__workspace {
    background: #fafafa;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
}

.badge__image {
    overflow: hidden;
    border: 4px solid #fff;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #fff;
}

.badge__image img {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
}

.badge__image.badge__image--height img {
    height: 100%;
    width: auto;
}

.badge__image.badge__image--force img {
    height: 100%;
    width: 100%;
}

.secondary__banner {
    background: #0d5491;
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
}

.badge__wrapper--DNA .secondary__banner{
    top: -60%;
    right: -35%;
    width: 120%;
    height: 180%;
    background: #ff5757;
    border-radius: 50%;
}

.badge__wrapper--DNA .secondary__banner--inner{
    position: absolute;
    top: -56%;
    right: -44%;
    width: 120%;
    height: 170%;
    background: #fff;
    border-radius: 50%;
}

.badge__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.specialty__banner {
    padding: 0.5em;
    text-align: center;
    background: #25bc0b;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
}

.badge__wrapper--DNA .specialty__banner{
    padding-top: 0.75em;
    background: #d9282e;
    padding-bottom: 0.75em;
    position: absolute;
    top: 12%;
    width: 100%
}

.index__counter {
    white-space: pre;
}

body .form__heading--narrow {
    padding-top: 0;
    padding-right: 0.2em;
    padding-bottom: 0;
}

body .form__heading.form__heading--primary,
body .MuiDialogContent-root .form__heading {
    color: #fff;
    background: #46759c;
}

.scope--Employee .details__view .form__heading {
    border-radius: 0;
}

.form__title {
    margin-bottom: 1em;
}

body {
    color: #444;
    margin: 0;
    min-height: 100vh;
    line-height: 1.5em;
    letter-spacing: initial;
    font-family: var(--font-family);
}

.MuiChip-label {
    font-family: var(--font-family);
}

body .chrome-picker {
    font-family: var(--font-family) !important;
}

.max__height{
    height: 100%;
}

.full__height {
    height: calc(100vh - 110px);
    overflow-y: scroll;
}

.full__height--left {
    /*height: calc(100vh - 178px);*/
    overflow-y: scroll;
}

.full__height--left-tabs {
    overflow-y: scroll;
}

.scope--User .full__height--left{
    height: calc(100vh - 158px);
}

.scope--User .full__height--left-tabs {
    height: calc(100vh - 210px);
}

input {
    font-family: var(--font-family);
}

body a {
    color: #1976d2;
}

a,
.breadcrumbs a {
    color: #222;
}

a,
a:hover,
a:focus,
a:active {
    cursor: pointer;
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

h4 {
    margin: 0;
}

.border__bottom {
    border-bottom: 1px solid var(--border-color);
}

.border__left {
    border-left: 1px solid var(--border-color);
}

.border__right {
    border-right: 1px solid var(--border-color);
}

.bullet__point {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.w__100 {
    display: block;
    width: 100%;
}

.image__reference{
    filter: grayscale(0);
    opacity: 1;
    transition: 0.5s ease-in-out;
}

.text__ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /*Width should be applied on an as-needed basis.*/
}

.text__highlight{
    background: #ffff6b;
}

.text__strike {
    text-decoration: line-through;
}

.text__bold {
    font-weight: bold;
}

.text__center {
    text-align: center;
}

body main {
    padding: 0;
}

body .text__undecorated{
    text-transform: initial;
}

.upload__center{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

body .call__chip {
    background-color: #e2e2e2;
}

body .call__chip:hover {
    background-color: #dadada;
}

.text__disclaimer {
    opacity: 0.6;
    font-weight: lighter;
    letter-spacing: 1px;
    pointer-events: none;
}

.text__light {
    opacity: 0.6;
}

.text__thin{
    font-weight: lighter;
}

body .text__small,
body span.text__small {
    font-size: var(--ancillary-font-size) !important;
}

.fixed-height__preview {
    height: 500px;
    padding: 1em;
    overflow-y: scroll;
}

.fixed-height__preview--large{
    height: 80vh;
}

.logo {
    display: flex;
    align-items: center;
}

.logo--drawer img {
    height: 40px;
    width: 40px;
}

.logo img {
    height: 40px;
    width: 40px;
}

.logo__text {
    font-weight: bold;
    vertical-align: top;
}

.pl__0 {
    padding-left: 0;
}

.p__0 {
    padding: 0;
}

.p__1 {
    padding: 0.2em;
}

.p__2 {
    padding: 0.5em;
}

.p__3 {
    padding: 1em;
}

.p__4 {
    padding: 1.5em;
}

.pl__0 {
    padding-left: 0;
}

.pl__1 {
    padding-left: 0.2em;
}

.pl__2 {
    padding-left: 0.5em;
}

.pl__3 {
    padding-left: 1em;
}

.pl__4 {
    padding-left: 1.5em;
}

.pr__0 {
    padding-right: 0;
}

.pr__1 {
    padding-right: 0.2em;
}

.pr__2 {
    padding-right: 0.5em;
}

.pr__3 {
    padding-right: 1em;
}

.pr__4 {
    padding-right: 1.5em;
}

.pt__0 {
    padding-top: 0;
}

.pt__1 {
    padding-top: 0.2em;
}

.pt__2 {
    padding-top: 0.5em;
}

.pt__3 {
    padding-top: 1em;
}

.pt__4 {
    padding-top: 1.5em;
}

.m__0 {
    margin: 0;
}

.mt__0 {
    margin-top: 0;
}

body .mb__0 {
    margin-bottom: 0;
}

.mb__1 {
    margin-bottom: 0.2em;
}

.mb__2 {
    margin-bottom: 0.5em;
}

.mb__3 {
    margin-bottom: 1em;
}

.mt__0 {
    margin-top: 0;
}

.mt__1 {
    margin-top: 0.2em;
}

.mt__2 {
    margin-top: 0.5em;
}

.mt__3 {
    margin-top: 1em;
}

.mr__0 {
    margin-right: 0;
}

body .mr__1 {
    margin-right: 0.2em;
}

body .mr__2 {
    margin-right: 0.5em;
}

body .mr__3 {
    margin-right: 1em;
}

body .mr__4{
    margin-right: 1.5em;
}

body .mr__5{
    margin-right: 3em;
}

body .ml__0 {
    margin-left: 0;
}

body .ml__1 {
    margin-left: 0.2em;
}

body .ml__2 {
    margin-left: 0.5em;
}

body .ml__3 {
    margin-left: 1em;
}

.cursor__pointer {
    cursor: pointer;
}

.d-hidden {
    display: none;
}

.d-inline__block {
    display: inline-block;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d__debug {
    opacity: 0;
    pointer-events: none;
}

.d-flex__grow {
    display: flex;
    flex-grow: 1;
}

.flex__grow {
    flex-grow: 1;
}

.d-flex__justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.d-flex__center {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.d-flex__start {
    display: flex;
    align-items: center;
}

.search {
    color: #333;
    background: #fff;
    border-radius: var(--border-radius);
    outline: none;
    border: none;
    width: 100%;
    height: 36px;
    font-size: var(--admin-font-size);
    padding-left: 1em;
    padding-right: 1em;
}

.help__icon {
    opacity: 0.5;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.help__icon:hover {
    opacity: 0.8;
}

.search__icon {
    position: absolute;
    right: 0.3em;
    top: 0.6em;
    color: black;
    opacity: 0.2;
}

body .search__icon .MuiCircularProgress-root{
    margin-top: 0.3em;
    margin-right: 0.3em;
    width: 1.25em!important;
    height: 1.25em!important;
}

.search__wrapper {
    min-width: 220px;
    position: relative;
}

.submenu {
    line-height: initial;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
    background-color: var(--secondary-menu-color);
    padding: 0.4em 0.3em;
}

.submenu__link {
    color: #222;
    font-size: var(--ancillary-font-size);
    padding-left: 1em;
    padding-right: 1em;
    text-decoration: none;
}

.submenu__link.active {
    font-weight: bold;
}

.footer {
    line-height: initial;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
    background: var(--secondary-menu-color);
    padding: 1em;
}

.footer__navigation {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.footer__navigation a {
    opacity: 0.8;
}

.breadcrumbs .divider,
.quick__filters .divider,
.file__attributes .divider,
.footer__navigation .divider,
.employee__credentials .divider,
.notifications__wrapper .divider {
    border-right: 1px solid var(--border-color);
    margin-left: 1em;
    margin-right: 1em;
}

.divider__wrapper .divider {
    height: 0.8em;
    border-right: 1px solid var(--border-color);
    margin-left: 0.75em;
    margin-right: 0.75em;
}

.file__wrapper{
    position: relative;
    text-align: center;
    color: initial;
    transition: 0.2s ease-in-out;
    border-radius: var(--border-radius);
    background: rgba(0,0,0,0);
    padding-top: 1em;
    padding-bottom: 1em;
    user-select: none;
}

.file__non-selectable{
    opacity: 0.5;
    pointer-events: none;
}

.file__menu {
    opacity: 0;
    transition: 0.2s ease-in-out;
    position: absolute!important;
    top: 0;
    right: 0;
}

.file__wrapper:hover .file__menu{
    opacity: 0.2;
}

.file__wrapper:hover .file__menu:hover{
    opacity: 1;
}

.file__wrapper.file__wrapper--selected{
    background: rgba(70, 170, 255, 0.1);
}

.file__wrapper--disabled{
    opacity: 0.5;
    pointer-events: none;
}

.file__wrapper.file__wrapper--selected:hover{
    background: rgba(70, 170, 255, 0.15);
}

.file__wrapper:not(.file__wrapper--selected):not(.file__non-selectable):hover{
    background: rgba(0,0,0,0.05);
}

.file__label{
    font-weight: bold;
    overflow: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: pre;
    word-break: break-all;
    margin-left: auto;
    margin-right: auto;
}

.file__wrapper svg{
    opacity: 0.5;
    transition: 0.2s ease-in-out;
}

.file__wrapper:hover svg{
    opacity: 1;
}

body .document-list__bulk-actions{
    top: 0;
    position: sticky;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2em;
    padding-left: 1em;
    background: #eff7ff;
    opacity: 0.9;
}

.document-list__bulk-actions .MuiAlert-message{
    padding: 0;
}

.document-list__table .file__wrapper{
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1em;
    border-radius: 0;
}

.document-list__table .file__thumbnail{
    margin-right: 0.5em;
}

.document-list__table .file__thumbnail .image__container{
    margin-bottom: 0;
}

.document-list__table .file__label{
    max-width: initial;
}

.file__attributes .divider,
.employee__credentials .divider {
    height: 1em;
    margin-left: 0.75em;
    margin-right: 0.75em;
}

.breadcrumbs .divider {
    height: 1em;
    margin-left: 0;
    margin-right: 0;
}

.quick__filters .crumb:last-child {
    padding-right: 0.5em;
}

.page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1.5em 1em;
    position: relative;
    /*min-height: calc(100vh - 92px);*/
}

.page.page--plain {
    /*min-height: calc(100vh - 134px);*/
}

.scope--User .page {
    /*min-height: calc(100vh - 119px);*/
}

.scope--Employee .page {
    /*min-height: calc(100vh - 92px);*/
}

.page--flex {
    display: flex;
    flex-direction: column;
}

.page__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5em;
}

.page.page--unauthenticated {
    /*min-height: calc(100vh - 92px);*/
}

#root .navigation {
    background: var(--navigation-color);
}

.columns__1,
.columns__2,
.columns__3,
.columns__4,
.columns__1_3,
.columns__2_3 {
    width: 100%;
    display: grid;
    gap: 1.5em;
    grid-gap: 1.5em;
}

.columns__1 {
    grid-template-columns: 1fr;
}

.columns__2 {
    grid-template-columns: 1fr 1fr;
}

.columns__3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.columns__4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.columns__1_3 {
    grid-template-columns: 1fr 2fr;
}

.columns__2_3 {
    grid-template-columns: 2fr 1fr;
}

.columns--small {
    grid-gap: 1em;
    gap: 1em;
}

.columns--large {
    grid-gap: 1.5em;
    gap: 1.5em;
}

.dotted__block {
    min-height: 300px;
    border: 1px dashed var(--border-color);
    border-radius: 0.5em;
    position: relative;
}

.widget__add {
    position: absolute;
    right: 0;
    top: 0;
}

.not__found {
    padding: 1.5em;
    text-align: center;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.crumb {
    display: flex;
    white-space: pre;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
}

.crumb svg {
    opacity: 0.6;
}

.breadcrumbs {
    font-size: var(--ancillary-font-size);
    line-height: initial;
    display: flex;
    align-items: center;
    background: var(--secondary-menu-color);
    padding: 0.5em;
    border-radius: 0.3em;
}

body .MuiTab-root {
    text-transform: initial;
    min-height: 51px;
}

body .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.2;
}

body .MuiDialogContent-root {
    overflow-y: initial;
}

#root .layout__control {
    padding-left: 0;
    padding-right: 0;
    min-width: 36px;
    border-radius: 50%;
}

.notifications__wrapper {
    position: relative;
    grid-row: 1 / 2;
}

.notifications__header {
    padding: 0.6em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notifications__header h3 {
    margin: 0;
}

.stream__container,
.notifications__body {
    position: relative;
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5em;
    grid-gap: 1.5em;
    overflow-y: scroll;
    height: calc(100vh - 261px)
}

.stream__container{
    height: calc(100vh - 288px)
}

.notification__body {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.notifications__body .notification__entry {
    margin-bottom: 1em;
}

.notification__wrapper {
    padding: 1em;
    flex-grow: 1;
    border-radius: var(--border-radius);
    margin-left: 0.75em;
    background-color: var(--row-accent);
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(0,0,0,0);
}

.notification__wrapper--response {
    margin-right: 5em;
}

.notification__wrapper--reply {
    background-color: #edf4fb;
    margin-left: 5em;
}

.notification__wrapper--pinned{
    box-shadow: 0 1px 6px rgba(0,0,0,0.1);
    border: 1px solid #e6e6e6;
}

.notification__wrapper--reply.notification__wrapper--pinned{
    border: 1px solid #c0ddf9;
}

.notification__timestamp {
    opacity: 0.5;
    display: flex;
    align-items: center;
    font-size: 0.8em;
}

.notification__indicator {
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background-color: #ececec;
    cursor: pointer;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

.notification__indicator--highlight {
    background-color: #6abcf9;
    box-shadow: 0 0 5px rgba(106, 188, 249, 0.5);
}

.notification__indicator--success {
    background-color: #4cac50;
}

.panel {
    display: flex;
    background: #fff;
    flex-direction: column;
}

.panel__header {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel__header h3 {
    margin: 0;
}

.panel__body {
    padding: 1em;
    flex-grow: 1;
}

.user-call__panel .panel__header {
    padding: 0.5em;
}

#root .MuiChip-label {
    font-size: var(--ancillary-font-size);
}

.file__loader{
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/
}

.file__list {
    max-height: 500px;
    overflow-y: scroll;
}

.document__list{
    height: calc(100vh - 400px);
    overflow-y: scroll;
    max-height: initial;
}

.file__body {
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.file__avatar{
    transition: 0.2s ease-in-out;
}

.file:hover {
    background: var(--row-accent);
}

.event__notes {
    overflow: hidden;
    max-width: 100px;
    max-height: 2em;
    text-overflow: ellipsis;
    white-space: pre;
    word-break: break-all;
}

.event-metrics__expiring .client-schedule__table{
    height: calc(100vh - 300px);
    max-height: initial;
}

.file__list .file {
    border-bottom: 1px solid var(--border-color);
}

.file__list .file__body {
    padding: 1em 0.5em;
}

.file__list .file:last-child {
    border-bottom: none;
}

.file__name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.file__attributes {
    margin-top: 0.3em;
    font-size: var(--ancillary-font-size);
}

.file__attributes svg {
    font-size: 1.3em;
    /*opacity: 0.6;*/
}

.drag__control {
    cursor: grab;
    opacity: 0.2;
}

.filter__drawer {
    font-size: var(--admin-font-size);
    transition: 0.2s ease-in-out;
    border-left: 1px solid var(--border-color);
}

.index__title svg {
    margin-right: 0.3em;
}

.employee__credentials {
    font-size: var(--ancillary-font-size);
    display: flex;
    align-items: center;
}

.generic__link.active {
    font-weight: bold;
}

body .asterisk {
    font-weight: bold;
    font-size: 1.2em;
    color: #bb1c15;
}

.details__view {
    min-height: calc(100vh - 200px);
}

.avatar__list--selected {
    background: #ccc;
}

#root .k-scheduler {
    /*height: calc(100vh - 179px) !important;*/
    overflow-y: hidden;
    flex-grow: 1;
}

#root .k-group-cell {
    white-space: pre;
    overflow: hidden;
}

#root .k-scheduler-monthview .k-event {
    /*height: initial!important;*/
}

#root .k-event-delete,
#root .k-scheduler-monthview .k-resize-handle {
    display: none;
}

#root .k-scheduler-monthview .k-event:not(.availability-event) > div[title] {
    font-weight: bold;
}

.page .availability-event {
    background: #5084b0;
}

.page .availability-event.k-selected {
    background: #50739f;
}

.calendar-item__time {
    white-space: pre;
}

.white-space__pre {
    white-space: pre;
}

.white-space__pre-line {
    white-space: pre-line;
}

#root .k-scheduler-day-view .calendar-item__time {
    display: none;
}

#root .k-scheduler-timeline-view > div {
    width: 2000px !important;
}

.page .list-item__row {
    flex-direction: column;
    align-items: start;
    padding: 0;
}

.quick-search__results .quick-search__result:first-of-type > li,
.page .list-item__row.list-item__row--selected {
    background: var(--row-accent);
}

.list-item__wrapper {
    width: 100%;
    display: block;
    padding: 0.5em 1em;
}

.button__group button {
    margin-right: 0.5em;
    min-width: initial;
}

.table--striped td {
    transition: 0.2s ease-in-out;
}

#root .k-scheduler-monthview .k-scheduler-group > .k-scheduler-row:nth-of-type(odd),
.alternating__list .list-item__row:nth-of-type(odd),
.table--striped tr:nth-of-type(odd) td {
    background: none;
}

#root .k-scheduler-monthview .k-scheduler-group > .k-scheduler-row:nth-of-type(even),
.alternating__list .list-item__row:nth-of-type(even),
.table--striped tr:nth-of-type(even) td {
    background: var(--row-accent);
}

.table--striped tr:nth-of-type(odd) td.row__updated {
    background: #e9f3fc;
}

.table--striped tr:nth-of-type(even) td.row__updated {
    background: #e4eef7;
}

#root .message__icon {
    position: fixed;
    right: 1.5em;
    bottom: 1.5em;
    z-index: 999;
}

.notification__wrapper:hover .message__action:not(.message__action--active){
    opacity: 0.2;
}

.message__action{
    color: inherit;
    opacity: 0;
    transition: ease-in-out;
}

.message__action--loading{
    pointer-events: none;
    opacity: 0.1;
}

.message__action--active{
    color: #4da6ff;
    opacity: 0.8;
}

.message__action:not(.message__action--loading):hover{
    opacity: 1;
}

.message-attachment__upload{
    padding-top: 0.4em!important;
    padding-bottom: 0.4em!important;
}

.notifications__wrapper .MuiSkeleton-root {
    opacity: 0.4;
}

body .MuiDrawer-paperAnchorRight .two-column__left-results{
    height: calc(100vh - 82px)!important;
}

body .MuiDrawer-paperAnchorRight .two-column__left-results--no-menu{
    height: calc(100vh)!important;
}

.two-column__left-results {
    height: calc(100vh - 258px);
    overflow-y: scroll;
}

.scope--User .two-column__left-results {
    height: calc(100vh - 234px);
}

.scope--User .two-column__left-results--no-menu{
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 158px);
}

.two-column__border-right {
    border-right: 1px solid var(--border-color);
}

.two-column__right {
    display: flex;
    position: relative;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: column;
}

.two-column__right--natural {
    position: relative;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
}

.two-column__right--dark {
    background: #fafafa;
}

.communication__controls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid var(--border-color);
}

.communication__collapse{
    cursor: pointer;
    position: absolute;
    top: -3.5em;
    right: 1.5em;
}

.communication__overview{
    padding: 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.communication__threads{
    flex-grow: 1;
    min-height: 500px;
}

.chat-thread__preview{
    overflow-x: hidden;
}

.chat-thread__preview:hover .chat-thread__snooze svg{
    opacity: 1;
}

.chat-thread__preview:hover .chat-thread__snooze-wrapper{
    width: 28px;
}

.chat-thread__snooze-wrapper{
    width: 0;
    transition: 0.1s ease-in-out;
}

.chat-thread__snooze svg{
    opacity: 0;
    transition: 0.1s ease-in-out;
}

body .tox-tinymce {
    border-radius: var(--border-radius);
    border: 1px solid #c4c4c4;
}

body .tox-statusbar__branding {
    display: none;
}

body .menu__error {
    color: #d32f2f;
}

body .menu__restore {
    color: rgb(73 156 77);
}

.quick-search__result .notification__indicator {
    /*width: 0.6em;*/
    /*height: 0.6em;*/
    opacity: 0.8;
}

#root .scheduler--editable .k-group-cell {
    padding-left: 1em;
    padding-right: 1em;
    padding-inline: var(--mobile-font-size);
}

tbody .MuiTableCell-root {
    border-bottom: none;
}

.loading__row td{
    /*height: 64px;*/
}

.k-scheduler-layout,
.scheduler__wrapper {
    transition: 0.1s ease-in-out;
}

.scheduler--loading {
    opacity: 0.5;
    pointer-events: none;
}

.scheduler--loading .k-scheduler-layout {
    /*filter: blur(2px)*/
}

.iframe__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.login__block {
    position: relative;
    margin-top: 6em;
    overflow: hidden;
    width: 30%;
    min-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.registration__banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

body .mobile__progress {
    height: 0.33em;
}

body .mobile__progress .MuiLinearProgress-bar {
    background-color: #47aafc;
}

.signature__pad {
    border: 1px solid grey;
    border-radius: var(--border-radius);
    background-color: #ffffff;
    width: 100%;
    display: block;
}

.stream__container {
    /*font-size: 14px;*/
    overflow-y: scroll;
    /*height: calc(100vh - 347px);*/
}



.well__container {
    background: #fcfcfc;
    border-radius: var(--border-radius);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
}

.table--responsive {
    overflow-x: scroll;
}

#root .availability__heading {
    min-width: 60px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.error__block {
    margin-top: 3em;
    text-align: left;
    border-radius: var(--border-radius);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
}

.error__block.mobile__notification {
    max-width: 768px;
}

.user__menu {
    text-align: right;
    white-space: pre;
    grid-column-start: 3;
}

.main-menu__container .MuiDivider-root{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.notifications__feed {
    width: 500px;
}

.notification--purple {
    color: #b836b8;
}

.notification--green {
    color: #23a123;
}

.notification--red {
    color: #d54a41;
}

body .v-align__top {
    vertical-align: top;
}

.details__right {
    min-width: 450px;
    width: 40%;
    margin-left: 1em;
}

body .bulk__seen,
body .bulk__seen.Mui-disabled {
    transition: 0.2s ease-in-out;
    left: 50%;
    bottom: 1.5em;
    opacity: 0;
    transform: translate(-50%, 0);
    position: absolute;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

body .bulk__seen:not(.MuiChip-colorSuccess) {
    background: #fff;
}

body .bulk__seen:not(.MuiChip-colorSuccess):hover {
    background: #efefef;
}

body .notifications__wrapper:hover .bulk__seen {
    opacity: 1;
}

.k-scheduler-views-wrapper {
    display: none;
}

.data__wrapper {
    height: 100%;
}

.data__wrapper th{
    white-space: pre;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.transcription__block {
    max-height: 300px;
    overflow-y: scroll;
}

.dashboard__call-log-feed .panel__header {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}

.dashboard__user-task-feed .text__strike {
    opacity: 0.3;
}

.dashboard__user-task-feed .panel__header {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.message__spacer {
    height: 8em;
}

.message__spacer.message__spacer--mobile {
    height: 12.5em;
}

.message__spacer--Email {
    height: 14em;
}

.fc-header-toolbar {
    padding: 1em;
    font-size: 0.8em;
}

body .fc a {
    color: #444;
}

.fc-media-screen {
    max-height: calc(100vh - 176px);
}

.calendar__wrapper {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}

body .fc-toolbar-chunk .fc-button-primary {
    text-transform: capitalize;
    background-color: #346690;
    border-color: #33536c;
}

body .schedule__mobile-preview,
body .schedule__drawer-toggle{
    display: none;
}

.availability__results .data__wrapper{
    height: initial;
    flex-grow: 1;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
}

.fc-daygrid-dot-event:hover {
    background: initial;
}

.fc-daygrid-dot-event:hover .calendar__event {
    background: #ffad9c;
    border: 1px solid #ff9c86;
}

.fc-event .calendar__event.availability-event {
    background: #9cc9f0;
    border: 1px solid #85b5de;
}

.fc-event .calendar__event.configuration-event {
    background: #86b5dd;
    border: 1px solid #6d9dc6;
    color: #fff;
}

.fc-event {
    display: block;
}

.fc-day-past {
    opacity: 0.5;
}

.fc-event .calendar__event {
    border-radius: var(--border-radius);
    background: #ff967f;
    border: 1px solid #e58169;
    padding-left: 0.3em;
    color: #444;
    overflow: hidden;
    transition: 0.2s ease-in-out;
}

.fc-event .calendar__event.calendar__event--selected {
    background: #e7745e;
    border: 1px solid #d05b43;
}

.fc-event .calendar__event.calendar__event--highlight {
    background: #ffec7f;
    border: 1px solid #dac75c;
}

.fc-event .calendar__event.calendar__event--highlight.calendar__event--selected {
    background: #dac75c;
    border: 1px solid #beac48;
}

.benefits__radio{
    justify-content: center;
    margin-bottom: 1em;
}

.slot-menu__controls{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.time-sheet__wrapper{
    position: relative;
}

.time-sheet__details{
    position: absolute;
    left: 1em;
    bottom: 1em;
    right: 2em;
    display: inline-block;
    padding: 1em;
}

.MuiDivider-wrapper{
    font-size: var(--ancillary-font-size);
}

.event__wrap {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

body .menu__referral {
    position: absolute;
    bottom: 0;
    border-top: 1px solid var(--border-color);
}

.chat__recent-conversations .chat-label__department{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

@media (max-width: 1350px) {
    .demo__block {
        display: none;
    }
}

@media (max-width: 1280px) {
    .admin__dashboard {
        grid-template-columns: 1fr 1fr;
    }

    .admin__dashboard .columns__2,
    .admin__dashboard .columns__2_3 {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 899px) {
    .full__height {
        height: calc(100vh - 86px);
    }

    .scope--User .full__height--left{
        height: calc(100vh - 135px);
    }

    .scope--User .full__height--left-tabs {
        height: calc(100vh - 187px);
    }

    .scope--User .two-column__left-results {
        height: calc(100vh - 210px);
    }

    .schedule__left-column{
        display: none;
    }

    body .schedule__mobile-preview,
    body .schedule__drawer-toggle{
        display: inherit;
    }

    .page__header {
        flex-direction: column;
        align-items: start;
        padding-bottom: 1.5em;
    }

    .scope--Employee .page__header {
        padding-bottom: 0;
    }

    *[role="tabpanel"] .columns__3 {
        grid-template-columns: 1fr 1fr;
    }

    .MuiDialogContent-root .breadcrumbs {
        display: none;
    }

    .details__body {
        flex-direction: column;
    }

    .details__right {
        margin-left: 0;
        margin-top: 1.5em;
        width: 100%;
    }

    .full__height {
        /*height: calc(100vh - 94px);*/
    }

    .admin__dashboard {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .employee__shift {
        display: flex;
        justify-content: initial;
        align-items: start;
        flex-direction: column;
    }

    .employee-shift__attributes {
        margin-top: 0.5em;
        text-align: left;
    }
}

@media (max-width: 580px) {
    .login__block {
        margin-top: 3em;
        min-width: auto;
        width: 100%;
    }

    .registration__banner {
        width: 100%;
        min-width: auto;
    }

    th > button {
        white-space: pre;
    }

    *[role="tabpanel"] .columns__3 {
        grid-template-columns: 1fr;
    }

    .login__block .columns__2 {
        grid-template-columns: 1fr;
    }

    .MuiPopover-paper .notifications__feed {
        width: 90vw;
    }
}

@media (max-width: 500px) {
    body {
        font-size: var(--mobile-font-size);
    }

    body .scope--User{
        font-size: var(--admin-font-size);
    }

    .scope--User .two-column__left-results {
        height: calc(100vh - 216px);
    }

    .scope--User .full__height--left{
        height: calc(100vh - 163px);
    }

    .scope--User .full__height--left-tabs {
        height: calc(100vh - 193px);
    }

    .search__wrapper {
        display: none;
    }

    .MuiPopover-paper .notification__timestamp {
        font-size: 0.8em;
    }

    body .notifications__menu {
        font-size: 0.8em;
    }

    .notification__wrapper--response {
        margin-right: 1em;
    }

    .notification__wrapper--reply {
        margin-left: 1em;
    }

    .stream__container {
        height: calc(100vh - 180px);
    }
}
